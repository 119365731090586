import React, { useCallback } from "react";
import Script from 'react-load-script'
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"

const IndexPage = () => {
  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  const onLoadFormrun = useCallback(() => {
    window.Formrun?.init('.formrun')
  }, [])

  return (
    <>
      <Script onLoad={onLoadFormrun} url="https://sdk.form.run/js/v2/formrun.js"></Script>

      {
        <Layout>
          <Seo
            keywords="お申し込み,トナリノ,デザイン,デザイン相談"
            description="サービスへのお申込みフォームです。"
            ogTitle="お申し込み"
            ogDescription="サービスへのお申込みフォームです"
            ogUrl="https://tonarino.co/order"
          />

            <PageTitleBox title="お申し込み" />
            <div className="container mb-5 pageBodyBox">
              <p data-formrun-hide-if-confirm>
                トナリノのお申し込みフォームです。ご利用の際は必ず<Link to="/rules">利用規約</Link>をご確認の上、お申込みください。<br />
                ご送信いただいてから、5日以内にメールでご連絡を差し上げます。
              </p>

              <section>
                <form
                  className="formrun"
                  action="https://form.run/api/v1/r/cm70abtlxdofz9xqc6js0epf"
                  method="post"
                >
                  <div className="form-group row mb-3">
                    <label
                      htmlFor="name"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      お名前
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        id="name"
                        name="お名前"
                        type="text"
                        className="form-control"
                        data-formrun-required
                        placeholder="田中 太郎"
                      />
                      <div data-formrun-show-if-error="お名前" className="mt-1 text-danger">
                        お名前を入力してください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="name"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      おなまえ（ふりがな）
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        id="name"
                        name="おなまえ（ふりがな）"
                        type="text"
                        className="form-control"
                        data-formrun-required
                        placeholder="たなか たろう"
                      />
                      <div data-formrun-show-if-error="おなまえ（ふりがな）" className="mt-1 text-danger">
                        おなまえ（ふりがな）を入力してください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="email"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      メールアドレス
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        id="email"
                        name="メールアドレス"
                        type="text"
                        data-formrun-type="email"
                        className="form-control"
                        data-formrun-required
                        placeholder="mail@tonarino.co"
                      />
                      <div data-formrun-show-if-error="メールアドレス" className="mt-1 text-danger">
                        メールアドレスを正しく入力してください
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="content"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      ご希望のプラン
                    </label>
                    <div className="col-12 col-md-8">
                      <div className="form-check">
                        <input
                          id="orderPlan1"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="仕様相談プラン"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan1">
                         仕様相談プラン
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="orderPlan2"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="表紙提案プラン（同人誌）"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan2">
                          表紙提案プラン（同人誌）
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="orderPlan2"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="デザイン提案プラン（一般向け）"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan2">
                          デザイン提案プラン（一般向け）
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="orderPlan3"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="アセットの制作プラン"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan3">
                          アセットの制作プラン
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="orderPlan4"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="その他プラン"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan4">
                          その他プラン
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          id="orderPlan5"
                          className="form-check-input"
                          type="radio"
                          name="ご希望のプラン"
                          value="迷っているので見積もり相談してから決めたい"
                          data-formrun-required
                        />
                        <label className="form-check-label" for="orderPlan5">
                          迷っているので見積もり相談してから決めたい
                        </label>
                      </div>
                      <div data-formrun-show-if-error="ご希望のプラン" className="mt-1 text-danger">
                        ご希望のプラン
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-3">
                    <label
                      htmlFor="content-large"
                      className="col-12 col-md-4 col-form-label d-block"
                    >
                      ご相談内容
                    </label>
                    <div className="col-12 col-md-8">
                      <textarea
                        id="content-large"
                        name="ご相談内容"
                        className="form-control"
                        data-formrun-required
                        col="8"
                      ></textarea>
                      <div data-formrun-show-if-error="ご相談内容" className="mt-1 text-danger">
                        ご相談内容を入力してください
                      </div>
                      <p className="small my-2">
                        ※なるべく詳細にお書きください。<br />
                        ファイルを添付したほうが良い場合はGoogle DriveやDropbox等にファイルアップロードしていただき、URLを送付してください。
                      </p>
                    </div>
                  </div>

                  <div className="_formrun_gotcha">
                    <label htmlFor="_formrun_gotcha">
                      If you are a human, ignore this field
                    </label>
                    <input
                      type="text"
                      name="_formrun_gotcha"
                      id="_formrun_gotcha"
                      tabIndex="-1"
                    />
                  </div>

                  <div className="g-recaptcha d-flex justify-content-center" data-sitekey="6LevJR8cAAAAAKEo17K9GEuRnFaAjbsiBWiwu9hG"></div>

                  <p className="text-center mt-4 mb-5">
                    <button
                      type="submit"
                      data-formrun-error-text="未入力の項目があります"
                      data-formrun-submitting-text="送信中..."
                      className="btn btn-primary btn-fixed-width rounded-pill py-3"
                    >
                      送信
                    </button>
                  </p>
                </form>
              </section>

              <p className="mb-0">
                個人情報の取り扱い詳細は「
                <Link to="/privacy">プライバシーポリシー</Link>
                」をご覧ください。
              </p>
            </div>

        </Layout>
      }
    </>)
}

export default IndexPage
