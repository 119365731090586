import * as React from "react"
import PropTypes from "prop-types"

const PageTitleBox = ({ title }) => {
  return (
    <div className="pageTitleBox py-5 text-center">
      <h1 className="m-0">{title}</h1>
    </div>
  )
}

PageTitleBox.propTypes = {
  title: PropTypes.string,
}

PageTitleBox.defaultProps = {
  title: ``,
}

export default PageTitleBox
